<template>
  <div class="common_tree_handle">
    <el-header
      style="
        text-align: right;
        font-size: 12px;
        height: auto;
        min-height: 46px;
        background-color: #ffffff;
        padding: 0 12px;
        display: flex;
        align-items: center;
      "
      ref="elHeader"
      v-if="titleShow"
    >
      <el-row
        style="
          display: flex;
          align-items: center;
          width: 100%;
          line-height: 45px;
          border-bottom: 1px solid #cccccc;
        "
      >
        <el-col
          span="18"
          style="float: left; display: flex; align-items: center"
        >
          <el-tooltip
            effect="dark"
            :content="treeTitle || this.$t('cip.cmn.title.typeTitle')"
            placement="top-start"
          >
            <label
              style="
                font-size: 14px;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{
                treeTitle === "" ? $t("cip.cmn.title.typeTitle") : treeTitle
              }}</label
            >
          </el-tooltip>
        </el-col>
        <el-col span="6" v-if="isShowdig">
          <template>
            <el-popover width="40" v-model="visible">
              <p class="popv_p" @click="add()">
                {{ $t("cip.cmn.btn.addBtn") }}
              </p>
              <p class="popv_p" @click="edit()">
                {{ $t("cip.cmn.btn.editBtn") }}
              </p>
              <p class="popv_p" @click="getdelete()">
                {{ $t("cip.cmn.btn.delBtn") }}
              </p>
              <el-button slot="reference" type="text">
                <i
                  class="el-icon-s-operation"
                  style="font-size: 18px; color: black"
                ></i>
              </el-button>
            </el-popover>
          </template>
        </el-col>
      </el-row>
    </el-header>
    <el-row
      style="padding: 14px 12px; background: #ffffff"
      v-if="isShowSearchInput"
    >
      <el-input
        size="small"
        :placeholder="$t('cip.cmn.msg.warning.filterateWarning')"
        v-model="filterText"
        class="searchInput"
      >
      </el-input>
    </el-row>
    <el-row
      style="padding: 14px 12px; background: #ffffff; text-align: center"
      v-if="isShowHeadButton"
    >
      <el-upload
        class="upload-demo"
        action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
        multiple
        :limit="1"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :on-exceed="handleExceed"
      >
        <el-button size="small" type="primary" icon="el-icon-upload"
          >点击上传</el-button
        >
      </el-upload>
    </el-row>
    <el-tree
      ref="commonTree"
      v-loading="loading"
      :data="treeChangeData"
      :props="defaultProps"
      :default-expand-all="treeExpand"
      :highlight-current="true"
      :show-checkbox="showCheckbox"
      :check-strictly="checkStrictly"
      :node-key="nodeKey"
      @node-click="nodeClick"
      @check-change="handleCheckChange"
      :filter-node-method="filterNode"
      style="font-size: 12px; border-radius: 4px; padding: 0 5px 0 12px"
    >
      <span slot-scope="{ node, data }" style="display: flex">
        <i
          v-if="data.children"
          class="el-icon-folder"
          style="line-height: 28px"
        ></i>
        <i v-else class="el-icon-document" style="line-height: 28px"></i>

        <!--        <el-tooltip effect="dark" :content="node.label">-->
        <span
          style="
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 200px;
            display: block;
          "
          >{{ node.label }}</span
        >
        <!--          </el-tooltip>-->
      </span>
    </el-tree>
    <!--  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      filterText: "",
      treeChangeData: [],
      nodeData: {},
      fileList: [],
    };
  },

  // treeData            树展示的数据
  // defaultProps        树展示的数据格式
  // searchTitle         过滤的字段名称
  // isShowdig           是否展示搜索旁边的下拉
  // showCheckbox        是否开启多选
  // isShowSearchInput   是否显示搜索框
  // @getTreeAdd         下拉新增方法
  // @getTreeEdit        下拉编辑方法
  // @getTreeDelete      下拉删除方法
  // @getNodeClick       节点点击方法
  // @getHandleCheckChange  多选节点选中状态发生变化时的回调

  props: {
    nodeKey: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    treeTitle: {
      type: String,
      default: "",
    },
    treeData: {
      type: Array,
    },
    treeForm: {
      type: Object,
      default: () => ({}),
    },
    treeFormOption: {
      type: Object,
      default: () => ({}),
    },
    defaultProps: {
      type: Object,
    },
    treeExpand: {
      type: Boolean,
      default: true,
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    searchTitle: {
      type: String,
      default: "",
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    isShowdig: {
      type: Boolean,
      default: false,
    },
    isShowSearchInput: {
      type: Boolean,
      default: true,
    },
    titleShow: {
      type: Boolean,
      default: true,
    },
    isShowHeadButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 新增
    add() {
      this.$emit("getTreeAdd", this.nodeData);
      this.visible = false;
    },
    // 编辑
    edit() {
      if (this.nodeData.id) {
        this.$emit("getTreeEdit", this.nodeData);
      } else {
        this.$message({
          message: this.$t("cip.cmn.msg.warning.editWarning"),
          type: "warning",
        });
      }

      this.visible = false;
    },
    // 删除
    getdelete() {
      if (this.nodeData.id) {
        // console.log("this.nodeData.id",this.nodeData)
        this.$emit("getTreeDelete", this.nodeData);
      } else {
        this.$message({
          message: this.$t("cip.cmn.msg.warning.deleteWarning"),
          type: "warning",
        });
      }
      this.visible = false;
    },
    nodeClick(data, node) {
      this.nodeData = data;
      this.$emit("getNodeClick", this.nodeData, node);
    },
    // 多选
    handleCheckChange(data, choosed) {
      // console.log(data);
      this.$emit("getHandleCheckChange", data, choosed);
    },

    filterNode(value, data) {
      if (!value) return true;

      return data[this.searchTitle].indexOf(value) !== -1;
    },
    /**
     * 递归过滤嵌套数组（树形结构）
     * @param arr {Array}要过滤的原始数据
     * @param teamName {Boolean} 你要过滤的关键词
     *
     */
    recursiveFilter(arr, teamName) {
      let result = [];
      arr.forEach((item1) => {
        if (item1[this.searchTitle].indexOf(teamName) != -1) {
          result.push(item1);
          if (item1.children) {
            //递归循环
            item1.children = this.recursiveFilter(item1.children, teamName);
          }
        } else {
          if (item1.children) {
            //递归循环
            item1.children = this.recursiveFilter(item1.children, teamName);
          }
        }
      });
      this.treeChangeData = result;
      return result;
      // this.searchResult = result
      // let data = arr.filter(item => item[this.searchTitle].indexOf(teamName)).map((item) => {
      //   console.log(item, 'fc');
      //   item = Object.assign({}, item)
      //   if (item.children) {
      //     //递归循环
      //     item.children = this.recursiveFilter(item.children, teamName)
      //   }
      //   return item
      // })
      // this.treeChangeData = data;
      // return data
    },
    checkNode(val) {
      this.$nextTick(() => {
        this.$refs.commonTree.setCurrentNode(this.nodeData);
      });

      console.log([this.nodeData], "vava");
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handleAvatarSuccess(res, file, fileList) {
      let fileLists = [];
      fileList.forEach((item) => {
        fileLists.push({
          extension: item.raw.type,
          id: item.response.data.attachId,
          link: item.response.data.link,
        });
      });
      this.$emit("fileList", fileLists);
    },
  },
  watch: {
    filterText(val) {
      this.$refs.commonTree.filter(val);
    },

    // filterText(n, o) {
    //   console.log(n, o, 'n,o');
    //   if (n && n.length > 0) {
    //     this.recursiveFilter(this.treeData, n);
    //   } else {
    //     this.treeChangeData = this.treeData;
    //   }
    // },
    treeData(n) {
      if (n) {
        this.treeChangeData = n;
      }
    },
  },
  mounted() {
    // this.treeChangeData = this.treeData;
  },
};
</script>

<style lang='scss' scoped>
.common_tree_handle {
  width: 280px;
  // padding-right: 12px;
  box-sizing: border-box;
  border-right: 1px solid #cccccc;
}

.popv_p {
  line-height: 32px;
  text-align: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.searchInput {
  // padding: 5px 0 6px;
  font-size: 12px;

  ::v-deep input {
    height: 28px;
  }
}
</style>
<style lang='scss' scoped>
.common_tree_handle {
  // .el-tree {
  //   /* height:100% !important; */
  //   height: calc(100vh - 222px) !important;
  //   overflow-y: scroll;
  // }

  .el-tree-node__content {
    height: 28px !important;
    line-height: 28px !important;
  }
}

.el-popover {
  min-width: 80px !important;
}
</style>
